import React from "react"
import LayoutA4 from "../../components/layout-a4"
import  LayoutA4Main from '../../components/layout-a4-main'
import  NewsA4 from '../../components/news-a4'
import homeStyles from './a4_home.module.css'
import FastEntryA4 from "../../components/fast-entry-a4"
import CountDownA4 from "../../components/countdown-a4"
import SupporterA4 from "../../components/supporters-a4"

export default  class A4Home extends React.Component {
  render() {

    return(
      <LayoutA4 >

          <LayoutA4Main>
            <div className={homeStyles.phone}>
              <CountDownA4 count={1000} />
              <FastEntryA4 />
            </div>

            <NewsA4 />
            <div className={homeStyles.phone}>
              <SupporterA4 />
            </div>
          </LayoutA4Main>
      </LayoutA4>
    )
  }
}